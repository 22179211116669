// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const GET_LIST_EXAM = "listExams";
export const GET_LIST_EXAM_SEARCH = "listExamSearch";
export const GET_LIST_EXAM_SEARCH_ELASTIC = "listExamSearchElastic";
export const GET_LIST_EXAM_COMMERCE_SEARCH = "listExamCommerceSearch";
export const APPROVAL_EXAM_COMMERCE = "approvalExamCommerce";
export const GET_EXAM_DETAIL = "detailExams";
export const ADD_EXAM = "addExams";
export const UPDATE_EXAM = "updateExams";
export const DELETE_EXAM = "deleteExams";
export const SET_CHECK = "checkBoxQuestion";
export const GET_DATA_FROM_EXCEL = "getDataFromExcel";
export const BLOCK_OR_UN_BLOCK_EXAM = "blockOrUnblockExam";
// mutation types
export const SET_DATA_FROM_EXCEL = "setDataFromExcel";
export const SET_LIST_EXAM = "getListExam";
export const SET_LIST_EXAM_SEARCH = "getListExamSearch";
export const SET_LIST_EXAM_COMMERCE_SEARCH = "setListExamCommerceSearch";
export const SET_EXAM_DETAIL = "list_questions";
export const SET_SELECT_QUESTION = "selectQuestion";

export const state = () => ({
    exams: [] as any,
    list_exam_search: [] as any,
    list_exam_commerce_search: [] as any,
    exam: {} as any,
    check_box_question: null as any,
    list_questions: [] as any,
    data_excels: [] as any,
    select_question: "",
    countClick: 0,
    loadedAllExams: false
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    exams: state => state.exams,
    list_exam_search: state => state.list_exam_search,
    list_exam_commerce_search: state => state.list_exam_commerce_search,
    exam: state => state.exam,
    select_question: state => state.select_question,
    countClick: state => state.countClick,
    checkBoxQuestion: state => state.check_box_question,
    list_questions: state => state.list_questions,
    data_excels: state => state.data_excels,
    loadedAllExams: state => state.loadedAllExams,
}

const mutations: MutationTree<RootState> = {
    [SET_LIST_EXAM](state, data) {
        state.exams = data;
    },
    [SET_SELECT_QUESTION](state, data) {
        state.select_question = data;
        state.countClick += 1;
    },
    [SET_CHECK](state, data) {
        state.check_box_question = data;
    },
    [SET_EXAM_DETAIL](state, data) {
        state.exam = data;
        state.list_questions = data.questions;
    },
    [SET_LIST_EXAM_SEARCH](state, data) {
        state.list_exam_search = data;
    },
    [SET_LIST_EXAM_COMMERCE_SEARCH](state, data) {

        state.list_exam_commerce_search = data;
    },
    [SET_DATA_FROM_EXCEL](state, data) {
        state.data_excels = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
    [GET_DATA_FROM_EXCEL]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.GET_DATA_FROM_EXCEL, payload).then((data: any) => {
                // commit(SET_DATA_FROM_EXCEL, data.data)
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response.data);
                });
        })
    },
    [GET_LIST_EXAM]({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_EXAM, '').then((data: any) => {
                if (data.data.status) {
                    commit(SET_LIST_EXAM, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [GET_LIST_EXAM_SEARCH]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_EXAM_SEARCH, payload).then((data: any) => {
                if (data.data.status) {
                    commit(SET_LIST_EXAM_SEARCH, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [GET_LIST_EXAM_SEARCH_ELASTIC]({ state }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_EXAM_SEARCH_ELASTIC, payload).then((data: any) => {
                if (data && data.data.status) {
                    const page = payload.page
                    const limit = payload.limit
                    const newExams = data.data.data
                    if (page == 1) {
                        state.list_exam_search = newExams
                    } else {
                        state.list_exam_search.push(...newExams)
                    }
                    if (!newExams || newExams.length == 0 || newExams.length < limit) {
                        state.loadedAllExams = true
                    }
                } else {
                    state.loadedAllExams = true
                }
                resolve(data)
            })
                .catch(({ response }) => {
                    reject(response);
                })
        })
    },
    [GET_LIST_EXAM_COMMERCE_SEARCH]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_EXAM_COMMERCE_SEARCH, payload).then((data: any) => {
                if (data.data.status) {
                    commit(SET_LIST_EXAM_COMMERCE_SEARCH, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [GET_EXAM_DETAIL]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.GET_EXAM_DETAIL, payload.id).then((data: any) => {
                if (data.data.status) {
                    commit(SET_EXAM_DETAIL, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    // eslint-disable-next-line
    [ADD_EXAM]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.ADD_EXAM, payload).then((data: any) => {
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response.data);
                });
        })
    },
    // eslint-disable-next-line
    [APPROVAL_EXAM_COMMERCE]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${API.APPROVAL_EXAM_COMMERCE}/${payload.id}`, payload.data).then((data: any) => {
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response.data);
                });
        })
    },
    // eslint-disable-next-line
    [UPDATE_EXAM]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(API.UPDATE_EXAM, payload.id, payload).then((data: any) => {
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response.data);
                });
        })
    },
    [DELETE_EXAM]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.UPDATE_EXAM, payload.id).then((data: any) => {
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response.data);
                });
        })
    },
    [BLOCK_OR_UN_BLOCK_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${API.BLOCK_OR_UN_BLOCK_EXAM}/${payload.id}`, payload.data)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },

}
const examModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default examModule
